import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Switches } from "../styles";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "./CircularLoader";
import Balances from "../user/Balances";
import Profile from "../user/Profile";
import Payments from "../user/Payments";
import Withdrawals from "../user/Withdrawals";
import Verifications from "../user/Verifications";
import Subscriptions from "../user/Subscriptions";
import UserTrades from "../user/userTrades";
import Stakings from "../user/Stakings";
import SignalPayments from "../user/SignalPayments";

const SingleUser = ({ id }) => {
  const options = [
    "Profile",
    "Trades",
    "Balances",
    "Payments",
    "Signal Deposits",
    "Verifications",
    "Stakings",
    "Subscriptions",
  ];
  const [activeOption, setActiveOption] = useState("Profile");
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getUser(id) {
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserData(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }

    if (id) {
      getUser(id);
    }
  }, [id]);

  useEffect(() => {
    if (userData) {
      setIsLoading(false);
    }
  }, [userData]);

  return (
    <>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Wrapper className="scrollbar-hidden">
          <Switches className="scrollbar-hidden switches">
            {options.map((opt) => (
              <button
                key={opt}
                className={opt === activeOption ? " active" : "switch"}
                onClick={(e) => setActiveOption(e.target.value)}
                value={opt}
              >
                {opt}
              </button>
            ))}
          </Switches>
          {activeOption === "Profile" && <Profile id={id} user={userData} />}
          {activeOption === "Balances" && <Balances id={id} user={userData} />}
          {activeOption === "Payments" && <Payments id={id} user={userData} />}
          {activeOption === "Withdrawals" && (
            <Withdrawals id={id} user={userData} />
          )}
          {activeOption === "Verifications" && (
            <Verifications id={id} user={userData} />
          )}
          {activeOption === "Subscriptions" && (
            <Subscriptions id={id} user={userData} />
          )}
           {activeOption === "Signal Deposits" && (
            <SignalPayments id={id} user={userData} />
          )}
          {activeOption === "Stakings" && (
            <Stakings id={id} user={userData} />
          )}
          {activeOption === "Trades" && <UserTrades id={id} user={userData} />}
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  /* background-color: #6a9494; */
  max-width: 100%;
  /* overflow-y: auto; */

  .switches {
    max-width: 100%;
    margin-top: 30px;
    overflow-y: auto;
  }
`;

export default SingleUser;
