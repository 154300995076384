import React, {  useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import { deleteDoc, deleteField, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import toast from "react-hot-toast";

const EditDepositModal = ({ open, depositRef, depositMethod, depositAmount }) => {
  const { editDeposit, setEditDeposit } = open;

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [method, setMethod] = useState(depositMethod)
  const [amount, setAmount] = useState(depositAmount)

  function handleMethod(e){
    const value = e.target.value;

    if (value) {
      setMethod(value);
    } else {
      setMethod(depositMethod);
    }
  }

  function handleAmount(e){
    const value = e.target.value;

    if (value) {
      setAmount(value);
    } else {
      setAmount(depositAmount);
    }
  }



  async function handleEdit() {
    setIsEditing(true);

    const q = doc(db, "deposits", depositRef);
    try {
      await updateDoc(q, {
            type: method,
            amount
      });
      toast.success("Updated successfully.");
      setIsEditing(false);
      setEditDeposit(false)
    } catch (error) {
      console.log(error);
      toast.error("Could not update. Please try again later");
      setIsEditing(false);
    }
  }

  async function handleDelete() {
    setIsDeleting(true);

    await deleteDoc(doc(db, "deposits", depositRef))
      .then(() => {
        toast.success(
          "Deleted successfully. The changes will update on next refresh."
        );
        setIsDeleting(false);
        setEditDeposit(false);
      })
      .catch((error) => {
        toast.error("Failed to delete. Please try again later.");
        setIsDeleting(false);
      });
  }

  return (
    <Modal
      open={editDeposit}
      onClose={() => setEditDeposit(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Edit {depositMethod} Deposit</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setEditDeposit(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>


                       <div
                          className="selectWrap"
                          style={{
                            boxSizing: "border-box",
                          }}
                        >
                          <label htmlFor="asset">Method</label>
                          <div
                            className="selectBox"
                            style={{
                              boxSizing: "border-box",
                              width: "100%",
                            }}
                          >
                            <div className="selectTop">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                  width: "100%",
                                  gap: "0.5rem",
                                  boxSizing: "border-box",
                                  padding: "1rem",
                                }}
                              >
                                <img
                                  src={`/asseticons/${method}.svg`}
                                  alt="icon"
                                  width={24}
                                  height={24}
                                />
                                <select
                                  name="asset"
                                  style={{
                                    padding: "0",
                                    width: "100%",
                                    boxSizing: "border-box",
                                    display: "flex",
                                  }}
                                  onChange={(e) => setMethod(e.target.value)}
                                >
                                  <option>BTC</option>
                                  <option>ETH</option>
                                  <option>USDT</option>
                                  <option>USDC</option>
                                  <option>DOGE</option>
                                  <option>DAI</option>
                                  <option>BCH</option>
                                  <option>APE</option>
                                  <option>SHIB</option>
                                  <option>USD</option>
                                </select>
                              </div>
                              <img
                                src="/extraicons/arrowdown.svg"
                                alt="select"
                                className="dropDownIcon"
                              />
                              {/* </span> */}
                            </div>
                          </div>
                        </div>
    

          <div className="addcon">
            <label htmlFor="new">Deposit Method</label>
            <div className="add">
              {/* <input
                onChange={(e) => handleName(e)}
                placeholder={plName}
                defaultValue={plName}
              /> */}
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Amount</label>
            <div className="add">
              <input
                onChange={(e) => handleAmount(e)}
                placeholder={amount}
                defaultValue={amount}
                type='number'
              />
            </div>
          </div>


          <div className="flex space-x-4">
            <button
              className={
                !method ||
                !amount ||
                isEditing ||
                isDeleting
                  ? "button disabled"
                  : "button submit"
              }
              disabled={
                !method ||
                !amount ||
                isEditing ||
                isDeleting
              }
              onClick={() => handleEdit()}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
              }}
            >
              {isEditing ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Save</p>
              )}
            </button>

            <button
              className={
                !method ||
                !amount ||
                isEditing ||
                isDeleting
                  ? "button disabled"
                  : "button submit"
              }
              disabled={
                !method ||
                !amount ||
                isEditing ||
                isDeleting
              }
              onClick={() => handleDelete()}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
                backgroundColor: "#E64B60",
              }}
            >
              {isDeleting ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Delete</p>
              )}
            </button>
          </div>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default EditDepositModal;
